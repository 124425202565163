import {
  SERIES_GET_INIT,
  SERIES_FOLLOWED_INIT,
  SERIES_GET_BY_ID_INIT,
  SET_SCOREDATA,
} from "redux/actionTypes/series.actionTypes";
// SERIES ACTIONS
export const getSeries = (payload) => {
  return { type: SERIES_GET_INIT, payload };
};

export const getSeriesById = (payload) => {
  return { type: SERIES_GET_BY_ID_INIT, payload };
};

// SERIES FOLLOWED ACTIONS
export const followedSeriesData = (payload) => {
  return { type: SERIES_FOLLOWED_INIT, payload };
};

export const setScoreData = (payload) => {
  return { type: SET_SCOREDATA, payload };
}
